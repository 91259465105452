import { DrawerLink } from '@/shared/types/components';
import IconHome from '@/assets/icons/icon-home.vue';
import IconUser from '@/assets/icons/icon-users.vue';
import IconTranslate from '@/assets/icons/icon-translate.vue';
import IconWrench from '@/assets/icons/icon-wrench-outline.vue';
import IconLeaderboard from '@/assets/icons/icon-leaderboard.vue';

const links: DrawerLink[] = [
  {
    to: '/organizations',
    icon: IconHome,
    label: 'nav.organizations',
  },
  {
    to: '/partners',
    icon: IconLeaderboard,
    label: 'nav.partners',
    permissions: ['AD_005'],
  },
  {
    to: '/admins',
    icon: IconUser,
    label: 'nav.admins',
  },
  {
    to: '/translations',
    icon: IconTranslate,
    label: 'nav.translations',
    permissions: ['AD_005'],
  },
  {
    to: '/challenge-scheduler',
    icon: IconWrench,
    label: 'nav.challengeScheduler',
    permissions: ['AD_005'],
  },
  {
    to: '/challenge-rewards',
    icon: IconLeaderboard,
    label: 'nav.challengeRewards',
    permissions: ['AD_005'],
  },
];

export default links;
